import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Treatment } from 'src/app/core/services/treatment.service';

@Component({
  selector: 'app-treatment-card',
  templateUrl: './treatment-card.component.html',
  styleUrls: ['./treatment-card.component.scss']
})
export class TreatmentCardComponent implements OnInit {
  @Input()
  treatment: Treatment;

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {}

  openTreatmentModal(modal: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modal);
  }
}
