import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ResponsiveBreakpointsService,
  ResponsiveBreakpoints
} from 'src/app/core/services/responsive-breakpoints.service';

@Component({
  selector: 'app-section-template-detail',
  templateUrl: './section-template-detail.component.html',
  styleUrls: ['./section-template-detail.component.scss']
})
export class SectionTemplateDetailComponent implements OnInit, OnDestroy {
  @Input()
  templateLeft: TemplateRef<any>;
  @Input()
  detailLeft: TemplateRef<any>;
  @Input()
  templateRight: TemplateRef<any>;
  @Input()
  detailRight: TemplateRef<any>;

  displaySmallerMd: boolean = true;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private responsiveBreakpointsService: ResponsiveBreakpointsService
  ) {}

  ngOnInit(): void {
    this.responsiveBreakpointsService.responsiveBreakpoints$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rB: ResponsiveBreakpoints) => {
        this.displaySmallerMd = rB.displaySmallerMd;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
