<div class="text-center px-4 px-sm-0 px-md-3 px-lg-4">
  <div
    class="card card-just-text treatment-bubble"
    (click)="openTreatmentModal(modal)"
  >
    <div class="treatment-bubble__content">
      <div class="test">
        <h4 class="card-title treatment-bubble__title">
          {{ treatment?.title }}
        </h4>

        <div class="divider divider--primary treatment-bubble__divider"></div>

        <h5 class="card-subtitle treatment-bubble__sub-title">
          <span *ngIf="treatment?.duration !== 0">
            {{ treatment?.duration }} min
          </span>

          <span *ngIf="treatment?.duration === 0">
            {{ treatment?.durationText }}
          </span>
        </h5>
      </div>
    </div>
  </div>

  <button class="btn btn-primary mt-3" (click)="openTreatmentModal(modal)">
    Details
  </button>
</div>

<ng-template #modal>
  <div class="treatment-modal">
    <button
      class="btn btn-primary btn-just-icon treatment-modal__close-btn"
      (click)="modalRef.hide()"
    >
      <i class="fa fa-close"></i>
    </button>

    <div class="card-body treatment-modal__body">
      <h3 class="treatment-modal__title">
        {{ treatment?.title }}
        {{ treatment?.subTitle ? treatment?.subTitle : '' }}
      </h3>

      <h5 class="treatment-modal__sub-title">
        <span *ngIf="treatment?.duration !== 0">
          {{ treatment.duration }} min
        </span>

        <span *ngIf="treatment?.duration === 0">
          {{ treatment?.durationText }}
        </span>
      </h5>

      <div
        class="divider divider--primary treatment-modal__divider--title"
      ></div>

      <p class="treatment-modal__description">
        {{ treatment?.description }}
      </p>

      <div
        *ngIf="treatment?.description"
        class="treatment-modal__divider"
      ></div>

      <ul class="key-words treatment-modal__features">
        <li *ngFor="let feature of treatment?.features">
          {{ feature }}
        </li>
      </ul>

      <div
        *ngIf="treatment?.features.length > 0"
        class="treatment-modal__divider"
      ></div>

      <p class="treatment-modal__price--left">Preis</p>
      <h5 class="treatment-modal__price--right">
        <small *ngIf="treatment?.priceTextBefore">
          {{ treatment?.priceTextBefore }}</small
        >
        {{ treatment?.price }} €
        <small *ngIf="treatment?.priceTextAfter">
          {{ treatment?.priceTextAfter }}</small
        >
      </h5>

      <div class="clearfix"></div>

      <div *ngFor="let extraPrice of treatment?.extraPrices">
        <p class="treatment-modal__price--left">
          {{ extraPrice.text }}
        </p>
        <h5 class="treatment-modal__price--right">{{ extraPrice?.price }} €</h5>

        <div class="clearfix"></div>
      </div>

      <!-- <a class="btn btn-primary" href="#">Termin vereinbaren</a> -->
    </div>
  </div>
</ng-template>
