<app-section [backgroundImg]="backgroundImg" [backgroundPos]="backgroundPos">
  <div
    class="page-header__wrapper d-flex align-items-center justify-content-center"
  >
    <div class="page-header__content">
      <app-section-header>
        <h1 class="title">{{ text }}</h1>
      </app-section-header>
    </div>
  </div>
</app-section>
