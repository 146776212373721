<app-nav></app-nav>
<router-outlet></router-outlet>
<app-footer></app-footer>

<button
  *ngIf="showScrollUpBtn"
  class="btn btn-primary btn-just-icon scroll-top-btn"
  (click)="scrollUp()"
>
  <i class="fa fa-arrow-up"> </i>
</button>

<div class="btn-wrapper" [class.btn-wrapper--mobile]="infoBtnWithIcon">
  <div class="text-right" *ngIf="!hideSocialButtons">
    <a
      class="btn btn-just-icon btn-instagram mb-1"
      role="button"
      href="https://www.instagram.com/schoenheitlich/?hl=de"
      target="_blank"
    >
      <i aria-hidden="true" class=" fa fa-instagram"> </i>
    </a>
  </div>

  <div class="text-right" *ngIf="!hideSocialButtons">
    <a
      class="btn btn-just-icon btn-google mb-5"
      role="button"
      href="https://www.google.com/maps/place/Sch%C3%B6nheitlich+Naturkosmetik/@49.9834914,7.9674345,15z/data=!4m5!3m4!1s0x0:0x19de5c919ee3b624!8m2!3d49.9834914!4d7.9674345"
      target="_blank"
    >
      <i aria-hidden="true" class="fa fa-google"> </i>
    </a>
  </div>

  <button
    *ngIf="infoBtnWithIcon"
    class="btn btn-primary btn-just-icon"
    (click)="openInfoModal()"
  >
    <i class="fa fa-info"> </i>
  </button>

  <button
    *ngIf="!infoBtnWithIcon"
    class="btn btn-primary"
    (click)="openInfoModal()"
  >
    Termin vereinbaren
  </button>
</div>
