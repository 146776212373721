<ng-container *ngIf="!displaySmallerMd">
  <div class="row">
    <div class="col-6 mt-auto mb-auto pr-lg-5 pr-md-3">
      <ng-container *ngIf="templateLeft">
        <ng-container *ngTemplateOutlet="templateLeft"></ng-container>
      </ng-container>

      <ng-container *ngIf="templateRight">
        <ng-container *ngTemplateOutlet="detailLeft"></ng-container>
      </ng-container>
    </div>

    <div class="col-6 mt-auto mb-auto pl-lg-5 pl-md-3">
      <ng-container *ngIf="templateLeft">
        <ng-container *ngTemplateOutlet="detailRight"></ng-container>
      </ng-container>

      <ng-container *ngIf="templateRight">
        <ng-container *ngTemplateOutlet="templateRight"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="displaySmallerMd">
  <div class="row">
    <div class="col-12 mb-5">
      <ng-container *ngIf="templateLeft">
        <ng-container *ngTemplateOutlet="templateLeft"></ng-container>
      </ng-container>

      <ng-container *ngIf="templateRight">
        <ng-container *ngTemplateOutlet="templateRight"></ng-container>
      </ng-container>
    </div>

    <div class="col-12">
      <ng-container *ngIf="detailRight">
        <ng-container *ngTemplateOutlet="detailRight"></ng-container>
      </ng-container>

      <ng-container *ngIf="detailLeft">
        <ng-container *ngTemplateOutlet="detailLeft"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
