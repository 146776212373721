import { Component } from '@angular/core';

@Component({
  selector: 'app-impressions',
  templateUrl: './impressions.component.html',
  styleUrls: ['./impressions.component.scss']
})
export class ImpressionsComponent {
  constructor() {}
}
