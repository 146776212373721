<app-section>
  <app-section-image-detail imgSrc="./assets/img/workshops-1.jpg">
    <h3 class="title">Workshops & Vorträge</h3>

    <div class="divider divider--primary"></div>

    <p>
      I love Workshops und Vorträge! In geselliger Runde etwas Neues zum Thema
      Naturkosmetik, Makeup und Schminktechniken erfahren? Endlich einmal danach
      wissen, welches die richtigen Produkte für Sie und Ihre Haut sind....
    </p>

    <p>
      In meinen Workshops und Vorträgen gehen wir genau darauf intensiv ein und
      das mit viel guter Laune, die uns in diesen Stunden begleitet.
    </p>

    <a
      class="btn btn-outline-primary mt-4 mt-md-3"
      [routerLink]="'/workshops-und-vortraege'"
    >
      Erfahre mehr
    </a>
  </app-section-image-detail>
</app-section>
