import { Component } from '@angular/core';

@Component({
  selector: 'app-home-about-me',
  templateUrl: './home-about-me.component.html',
  styleUrls: ['./home-about-me.component.scss']
})
export class HomeAboutMeComponent {
  constructor() {}
}
