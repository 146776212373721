import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImpressionsRoutingModule } from './impressions-routing.module';
import { ImpressionsComponent } from './pages/impressions/impressions.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxGalleryModule } from 'ngx-gallery';
import { ImpressionsGalleryComponent } from './components/impressions-gallery/impressions-gallery.component';

@NgModule({
  declarations: [ImpressionsComponent, ImpressionsGalleryComponent],
  imports: [
    CommonModule,
    ImpressionsRoutingModule,
    SharedModule,
    NgxGalleryModule
  ]
})
export class ImpressionsModule {}
