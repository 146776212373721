import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

export interface ResponsiveBreakpoints {
  displayXs: boolean;
  displaySm: boolean;
  displayMd: boolean;
  displayLg: boolean;
  displayXl: boolean;
  displaySmallerSm: boolean;
  displaySmallerMd: boolean;
  displaySmallerLg: boolean;
  displaySmallerXl: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ResponsiveBreakpointsService implements OnDestroy {
  responsiveBreakpoints$: BehaviorSubject<ResponsiveBreakpoints>;

  private breakpointSm: number = 576;
  private breakpointMd: number = 768;
  private breakpointLg: number = 992;
  private breakpointXl: number = 1200;

  private isXs: boolean = false;
  private isSm: boolean = false;
  private isMd: boolean = false;
  private isLg: boolean = false;
  private isXl: boolean = false;

  private lastBreakpoints: ResponsiveBreakpoints;

  constructor() {
    this.responsiveBreakpoints$ = new BehaviorSubject(this.checkBreakpoints());

    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', () => {
      this.onResize();
    });

    this.responsiveBreakpoints$.complete();
  }

  private onResize(): void {
    const currentBreakpoints: ResponsiveBreakpoints = this.checkBreakpoints();

    if (this.checkNewBreakpoints()) {
      this.responsiveBreakpoints$.next({ ...currentBreakpoints });

      this.lastBreakpoints = currentBreakpoints;
    }
  }

  private checkNewBreakpoints(): boolean {
    if (!this.lastBreakpoints) {
      return true;
    }

    if (this.lastBreakpoints.displayXs !== this.isXs) {
      return true;
    }
    if (this.lastBreakpoints.displaySm !== this.isSm) {
      return true;
    }
    if (this.lastBreakpoints.displayMd !== this.isMd) {
      return true;
    }
    if (this.lastBreakpoints.displayLg !== this.isLg) {
      return true;
    }
    if (this.lastBreakpoints.displayXl !== this.isXl) {
      return true;
    }

    return false;
  }

  private checkBreakpoints(): ResponsiveBreakpoints {
    const windowWidth: number = window.outerWidth;

    this.isXs = windowWidth < this.breakpointSm;
    this.isSm =
      windowWidth >= this.breakpointSm && windowWidth < this.breakpointMd;
    this.isMd =
      windowWidth >= this.breakpointMd && windowWidth < this.breakpointLg;
    this.isLg =
      windowWidth >= this.breakpointLg && windowWidth < this.breakpointXl;
    this.isXl = windowWidth >= this.breakpointXl;

    return {
      displayXs: this.isXs,
      displaySm: this.isSm,
      displayMd: this.isMd,
      displayLg: this.isLg,
      displayXl: this.isXl,
      displaySmallerSm: this.isXs,
      displaySmallerMd: this.isSm || this.isXs,
      displaySmallerLg: this.isMd || this.isSm || this.isXs,
      displaySmallerXl: this.isLg || this.isMd || this.isSm || this.isXs
    };
  }
}
