<app-section [backgroundImg]="backgroundImg">
  <div class="quote__wrapper d-flex align-items-center justify-content-center">
    <div class="quote__box p-3 p-md-5">
      <app-section-header>
        <h2 class="title m-0">&bdquo;{{ quote }}&ldquo;</h2>

        <p class="description mt-3 mb-0 text-center">{{ author }}</p>
      </app-section-header>
    </div>
  </div>
</app-section>
