import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-arrange-date',
  templateUrl: './arrange-date.component.html',
  styleUrls: ['./arrange-date.component.scss']
})
export class ArrangeDateComponent {
  constructor(public bsModalRef: BsModalRef) {}
}
