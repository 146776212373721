<app-section>
  <h2 class="text-center mb-5">IMPRESSUM</h2>

  <div>
    <p class="header">
      SCHÖNHEITLICH Naturkosmetik <br />
      Carmen Jelinek
    </p>
  </div>

  <div>
    <h4>Postanschrift:</h4>
    <p>
      Römerberg 5a <br />
      65366 Geisenheim
    </p>
  </div>

  <div>
    <h4>Kontakt:</h4>
    <p>
      Tel. +49 6722 7100777 <br />
      Internet: http://www.schoenheitlich.de <br />
      E-Mail: info@schoenheitlich.de
    </p>
  </div>

  <div>
    <h4>Vertretungsberechtigter im Sinne § 5 Abs. 1 Telemediengesetz:</h4>
    <p>
      Geschäftsführerin <br />
      Carmen Jelinek
    </p>
  </div>

  <div>
    <h4>Information Rechtsstatus:</h4>
    <p>
      Steuernummer: <br />
      Amtsgericht
    </p>
  </div>

  <div>
    <p>
      Das Copyright liegt ausdrücklich unter Vorbehalt bei SCHÖNHEITLICH
      Naturkosmetik. <br />
      Die gewerbliche und kommerzielle Verwendung von Inhalten, Designs, und
      Bildern ist nicht erlaubt und unterliegt dem Urheberrecht von
      SCHÖNHEITLICH Naturkosmetik. <br />
      Carmen Jelinek respektiert die Privatsphäre ihrer Kunden. Sie behandelt
      persönliche Daten von Nutzern vertraulich und stellt diese nur insoweit
      zur Verfügung, als dies durch Datenschutzgesetze erlaubt ist oder der
      Kunde seine Einwilligung gibt. <br />
      Den für den gesamten Internetauftritt unter der Domain
      www.schoenheitlich.de geltenden Haftungsausschluss können Sie unter
      www.disclaimer.de/disclaimer.htm nachlesen.
    </p>
  </div>

  <div>
    <h4>Corporate Identity/Logoentwicklung:</h4>
    <p>
      Miriam Lutz <br />
      Jessica Jelinek
    </p>
  </div>

  <div>
    <h4>Fotos:</h4>
    <p>Dr. Joachim Jäger</p>
  </div>

  <div>
    <h4>Texte:</h4>
    <p>
      Carmen Jelinek <br />
      Aljoscha Jelinek
    </p>
  </div>

  <div>
    <h4>Webdesign:</h4>
    <p>Nils Funke</p>
  </div>
</app-section>

<app-map></app-map>
