import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataPrivacyComponent } from './core/data-privacy/data-privacy.component';
import { ImpressumComponent } from './core/impressum/impressum.component';
import { HomeComponent } from './modules/home/pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'ueber-mich',
    loadChildren: () =>
      import('./modules/about-me/about-me.module').then(
        mod => mod.AboutMeModule
      )
  },
  {
    path: 'behandlungen',
    loadChildren: () =>
      import('./modules/treatments/treatments.module').then(
        mod => mod.TreatmentsModule
      )
  },
  {
    path: 'makeup',
    loadChildren: () =>
      import('./modules/makeup/makeup.module').then(mod => mod.MakeupModule)
  },
  {
    path: 'workshops-und-vortraege',
    loadChildren: () =>
      import('./modules/workshops/workshops.module').then(
        mod => mod.WorkshopsModule
      )
  },
  {
    path: 'produkte',
    loadChildren: () =>
      import('./modules/products/products.module').then(
        mod => mod.ProductsModule
      )
  },
  {
    path: 'impressionen',
    loadChildren: () =>
      import('./modules/impressions/impressions.module').then(
        mod => mod.ImpressionsModule
      )
  },
  {
    path: 'datenschutz',
    component: DataPrivacyComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
