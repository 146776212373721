<app-section-template-detail
  [templateLeft]="imgLeft ? img : null"
  [templateRight]="imgRight ? img : null"
  [detailLeft]="!imgLeft ? detail : null"
  [detailRight]="!imgRight ? detail : null"
>
  <ng-template #img>
    <div class="h-100">
      <img *ngIf="displaySmallerMd || displayXl" class="img" [src]="imgSrc" />

      <img
        *ngIf="!displaySmallerMd && !displayXl"
        class="img"
        [src]="imgSrc"
        [ngClass]="imgLeft ? 'img--left' : 'img--right'"
      />
    </div>
  </ng-template>

  <ng-template #detail>
    <ng-content></ng-content>
  </ng-template>
</app-section-template-detail>
