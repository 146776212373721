<app-section>
  <app-section-header>
    <h3 class="title">Lassen Sie sich inspirieren</h3>

    <div class="divider divider--primary mb-0"></div>

    <p class="text-center">
      In meinem Institut sollen Sie und Ihre Haut zur Ruhe kommen! Harmonie in
      den Farben der Einrichtung, Naturelemente und Ästhetik waren mir besonders
      wichtig und sollen Ihnen während Ihrem Aufenthalt bei mir Wohlgefühl und
      Gemütlichkeit vermitteln. Einfach SCHÖNheitlich...
    </p>
  </app-section-header>

  <div class="space-top"></div>

  <div #galleryWrapper>
    <ngx-gallery
      #gallery
      [options]="galleryOptions"
      [images]="galleryImages"
    ></ngx-gallery>
  </div>
</app-section>
