import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArrangeDateComponent } from 'src/app/shared/components/arrange-date/arrange-date.component';
@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent {
  constructor(private modalService: BsModalService) {}

  openInfoModal(): void {
    this.modalService.show(ArrangeDateComponent);
  }
}
