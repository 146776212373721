<app-section backgroundImg="./assets/img/shop.jpg" backgroundPos="top">
  <div class="header__wrapper d-flex align-items-center justify-content-center">
    <div class="header__content">
      <app-section-header>
        <h1 class="title m-0">Willkommen bei Schönheitlich</h1>

        <div class="divider divider--white"></div>
      </app-section-header>

      <div class="row text-center">
        <div class="col-12 col-md-6 tex-center text-md-right my-2">
          <button class="btn btn-neutral btn" (click)="openInfoModal()">
            Termin oder Workshop anfragen
          </button>
        </div>

        <div class="col-12 col-md-6 text-center text-md-left my-2">
          <button
            class="btn btn-outline-neutral btn"
            (click)="openInfoModal()"
          >
            Gutschein bestellen
          </button>
        </div>
      </div>
    </div>
  </div>
</app-section>
