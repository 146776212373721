import { Component } from '@angular/core';

@Component({
  selector: 'app-home-workshops',
  templateUrl: './home-workshops.component.html',
  styleUrls: ['./home-workshops.component.scss']
})
export class HomeWorkshopsComponent {
  constructor() {}
}
