import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MakeupRoutingModule } from './makeup-routing.module';
import { MakeupComponent } from './pages/makeup/makeup.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MakeupCardsComponent } from './components/makeup-cards/makeup-cards.component';

@NgModule({
  declarations: [MakeupComponent, MakeupCardsComponent],
  imports: [CommonModule, MakeupRoutingModule, SharedModule]
})
export class MakeupModule {}
