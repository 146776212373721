<footer class="footer footer-big pb-1">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3 mt-auto mb-lg-auto mb-4 text-center px-5">
        <img class="footer-logo" src="assets/img/logos/logo.svg" />
      </div>

      <div class="col-12 col-md-4 col-lg-3 text-center text-md-left mb-1">
        <div class="links">
          <ul class="stacked-links">
            <li class="p-0 mb-3">
              <h5 class="text-uppercase font-weight-normal">Öffnungszeiten</h5>
            </li>

            <li class="p-0 mb-2">
              <h5>
                Montag - Freitag:
                <br />
                <small> Termine nach Absprache </small>
              </h5>
            </li>

            <li class="p-0">
              <h5>
                Samstag: 11 - 14 Uhr
                <br />
                <small>
                  nur Beratung & Verkauf, kein Termin erforderlich
                </small>
              </h5>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-3 text-center text-md-left mb-1">
        <div class="links">
          <ul class="stacked-links">
            <li class="p-0 mb-3">
              <h5 class="text-uppercase font-weight-normal">Kontakt</h5>
            </li>

            <li class="p-0 mb-2">
              <a
                class="btn btn-link btn-primary p-0 footer--link text-center text-md-left"
                href="https://www.google.com/maps/place/Sch%C3%B6nheitlich+Naturkosmetik/@49.9834914,7.9652458,17z/data=!3m1!4b1!4m5!3m4!1s0x47bdefa0fcbafaa7:0x19de5c919ee3b624!8m2!3d49.9834914!4d7.9674345"
                target="_blank"
              >
                Römerberg 5A
                <br />
                65366 Geisenheim
              </a>
            </li>

            <li class="p-0 mb-2">
              <a
                class="btn btn-link btn-primary p-0 footer--link text-center text-md-left"
                href="tel:06722 7100777"
              >
                06722 - 71 00 777
              </a>
            </li>

            <li class="p-0">
              <a
                class="btn btn-link btn-primary p-0 footer--link text-center text-md-left"
                href="/"
              >
                info@schoenheitlich.de
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-3 text-center text-md-left mb-1">
        <div class="links">
          <ul class="stacked-links">
            <li class="p-0 mb-3">
              <h5 class="text-uppercase font-weight-normal">Folge mir</h5>
            </li>

            <li>
              <a
                class="btn btn-just-icon btn-instagram mx-1"
                role="button"
                href="https://www.instagram.com/schoenheitlich/?hl=de"
                target="_blank"
              >
                <i aria-hidden="true" class="fa fa-instagram"> </i>
              </a>

              <a
                class="btn btn-just-icon btn-google mx-1"
                role="button"
                href="https://www.google.com/maps/place/Sch%C3%B6nheitlich+Naturkosmetik/@49.9834914,7.9674345,15z/data=!4m5!3m4!1s0x0:0x19de5c919ee3b624!8m2!3d49.9834914!4d7.9674345"
                target="_blank"
              >
                <i aria-hidden="true" class="fa fa-google"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="container">
    <div class="copyright">
      <div class="pull-left">
        &copy; {{ date | date : "yyyy" }} Copyright SCHÖNheitlich
      </div>
      <div class="links pull-right">
        <ul>
          <li>
            <a routerLink="impressum"> Impressum </a>
          </li>
          |
          <li>
            <a routerLink="datenschutz"> Datenschutz </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
