<app-section>
  <app-section-image-detail imgSrc="./assets/img/about-me-1.jpg">
    <h3 class="title">Über mich</h3>

    <div class="divider divider--primary"></div>

    <p>
      Seit 1983 bin ich als Kosmetikerin, staatl.geprüft, Visagistin und
      Trainerin beruflich aktiv. Viele Jahre arbeitete ich als Leitung der
      Trainingsabteilung für die Parfümerie Douglas und die Firma CHANEL.
    </p>

    <p>
      Jetzt ist es an der Zeit mein Wissen an SIE weiterzugeben. In meinem
      Kosmetikinstitut SCHÖNheitlich stehe ich Ihnen mit meinem KNOW–HOW zur
      Verfügung.
    </p>

    <a class="btn btn-outline-primary mt-4 mt-md-3" routerLink="/ueber-mich">
      Erfahre mehr
    </a>
  </app-section-image-detail>
</app-section>
