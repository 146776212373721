<!-- <app-section [noContainer]="true" [noPadding]="true">
  <div id="contactUsMap2" class="big-map">
    <agm-map
      [style.height]="height + 'px'"
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [styles]="styles"
    >
      <agm-marker [latitude]="lat" [longitude]="lng">
        <agm-snazzy-info-window
          [maxWidth]="200"
          [isOpen]="true"
          [padding]="'16px'"
        >
          <ng-template>
            <p class="m-0">
              Römerberg 5A <br />
              65366 Geisenheim
            </p>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>
    </agm-map>
  </div>
</app-section> -->

<iframe
  style="width: 100vw;"
  [height]="height"
  frameborder="0"
  scrolling="no"
  marginheight="0"
  marginwidth="0"
  src="https://www.openstreetmap.org/export/embed.html?bbox=7.965635909931734%2C49.98260061458252%2C7.969176425831394%2C49.98425976141898&amp;layer=mapnik&amp;marker=49.983430195153744%2C7.967406167881563"
></iframe
><br /><small></small>
