<app-section>
  <app-section-image-detail imgSrc="./assets/img/treatments-1.jpg">
    <h3 class="title">Organic Treatments</h3>

    <div class="divider divider--primary"></div>

    <p>
      Mein Ansatz für Ihre Schönheit: Moderne Natur HIGH TECH Pflegesysteme, die
      Ihre natürliche Schönheit zum Strahlen bringt! Professionaltät, Kompetenz
      und dabei immer authentisch sein, hat für mich höchste Priorität.
    </p>

    <p>
      Kein Überangebot an Behandlungen, aber wohltuende, auserlesende und
      hochwirksame Treatments, die Sie begeistern werden.
    </p>

    <a class="btn btn-outline-primary mt-4 mt-md-3" routerLink="/behandlungen">
      Erfahre mehr
    </a>
  </app-section-image-detail>
</app-section>
