<app-section>
  <app-section-header>
    <h2 class="title">How to Makeup</h2>

    <div class="divider divider--primary"></div>

    <p>
      Natürlich SCHÖN mit Mineral Makeup, welches Ihre Haut im besten Licht
      erstrahlen lässt. Mit Makeup sich wohlfühlen, selbstsicher auftreten, die
      eigene Persönlichkeit unterstreichen. Das sind die Beratungspunkte, die
      mir sehr wichtig sind.
    </p>
  </app-section-header>

  <div class="row justify-content-center">
    <div
      *ngFor="let treatment of makeupTreatments"
      class="col-lg-4 col-sm-6 my-3 my-md-4 px-5"
    >
      <app-treatment-card [treatment]="treatment"></app-treatment-card>
    </div>
  </div>
</app-section>
