<app-home-header></app-home-header>
<app-home-about-me></app-home-about-me>
<app-home-products></app-home-products>
<app-section-quote
  [backgroundImg]="quoteImg"
  [quote]="quote"
  [author]="quoteAuthor"
></app-section-quote>
<app-home-treatments></app-home-treatments>
<app-home-makeup></app-home-makeup>
<app-home-workshops></app-home-workshops>
<!-- <app-home-impressions></app-home-impressions> -->
<app-map [height]="400"></app-map>
