<div class="info-card">
  <button
    class="btn btn-primary btn-just-icon info-card__close-btn"
    (click)="bsModalRef.hide()"
  >
    <i class="fa fa-close"></i>
  </button>

  <div class="card-body info-card__body text-center">
    <h4 class="title info-card__title px-5">Ich freue mich auf Sie!</h4>
    <div class="divider divider--primary"></div>

    <h5 class="info-card__text">
      Montag - Freitag:
      <br />
      <small> Termine nach Absprache </small>
    </h5>

    <h5 class="info-card__text mt-2">
      Samstag: 11 - 14 Uhr
      <br />
      <small> nur Beratung & Verkauf, kein Termin erforderlich </small>
    </h5>

    <div class="info-card__divider"></div>

    <a
      class="btn btn-link btn-primary p-0 info-card__link"
      href="https://www.google.com/maps/place/Sch%C3%B6nheitlich+Naturkosmetik/@49.9834914,7.9652458,17z/data=!3m1!4b1!4m5!3m4!1s0x47bdefa0fcbafaa7:0x19de5c919ee3b624!8m2!3d49.9834914!4d7.9674345"
      target="_blank"
    >
      Römerberg 5A
      <br />
      65366 Geisenheim
    </a>

    <a
      class="btn btn-link btn-primary p-0 info-card__link"
      href="tel:06722 7100777"
    >
      06722 - 71 00 777
    </a>

    <a class="btn btn-link btn-primary p-0 info-card__link" href="/">
      info@schoenheitlich.de
    </a>
  </div>
</div>
