import { Injectable } from "@angular/core";

export enum TreatmentTypes {
  Face = "Gesicht",
  Body = "Körper",
  Upgrades = "Upgrades, Wimpern & Brauen",
  Makeup = "Makeup",
}

export interface ExtraPrice {
  text: string;
  price: number;
}

export interface Treatment {
  type: TreatmentTypes;
  title: string;
  subTitle?: string;
  duration: number;
  durationText?: string;
  description?: string;
  features: string[];
  price: number;
  priceTextAfter?: string;
  priceTextBefore?: string;
  extraPrices: ExtraPrice[];
}

@Injectable({
  providedIn: "root",
})
export class TreatmentService {
  private treatments: Treatment[] = [];

  constructor() {
    this.initTreatments();
  }

  getTreatments(): Treatment[] {
    return [...this.treatments];
  }

  getTreatmentsByType(type: TreatmentTypes): Treatment[] {
    const treatmentsByType: Treatment[] = this.treatments.filter(
      (treatment: Treatment) => {
        return treatment.type === type;
      }
    );

    return [...treatmentsByType];
  }

  private initTreatments(): void {
    this.treatments = [
      {
        type: TreatmentTypes.Face,
        title: "Pur",
        duration: 65,
        description:
          "Hautspezifische Tiefenreinigung speziell für unreine Haut und jedes Hautalter nach Bedürfnis.",
        features: [
          "Hautanalyse",
          "Reinigung mit Spezialpeeling",
          "Ausreinigen der Haut",
          "Maske nach Hautbedürfnis",
          "Pflege und Beratung",
        ],
        price: 95,
        extraPrices: [
          {
            text: "Schüler und Studenten mit Nachweis",
            price: 75,
          },
          {
            text: "Kinder und Teenager bis 16 Jahre",
            price: 60,
          },
        ],
      },
      {
        type: TreatmentTypes.Face,
        title: "Beauty Run",
        duration: 50,
        description:
          "Schnell und effektiv, kurzes Relaxen für Sie und Auftanken für Ihre Haut - Feuchtigkeit, Energie und Sättigung.",
        features: [
          "Hautanalyse",
          "Reinigung mit Spezialpeeling",
          "Ampulle",
          "Minimassage",
          "Maske",
          "Pflege und Beratung",
        ],
        price: 85,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Calm Down",
        duration: 75,
        description:
          "Beruhigung und Balance für die sensible und gestresste Haut - Rötungen und Irritationen ade.",
        features: [
          "Hautanalyse",
          "Reinigung mit ev. Spezialpeeling",
          "Kl. Lymphdrainage",
          "Beruhigendes Spezialserum",
          "Tiefenentspannende Massage",
          "Maske",
          "Pflege und Beratung",
        ],
        price: 105,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Wellaging Ultraschall",
        duration: 70,
        description:
          "Tiefenwirksames Treatment mit Einschleusen von hochkonzentrierten, naturkosmetischen Wirkstoffgelen. Eine Wohltat für Ihre Haut!",
        features: [
          "Hautanalyse",
          "Lymphstimulierende Massage",
          "Kräuter Dampfkompressen",
          "Microdermabrasion und Tiefenreinigung",
          "Ultraschallbehandlung",
          "Intensivmaske mit Massage",
          "Tages- oder Nachtpflege",
          "Auf Wunsch kl. Makeup",
        ],
        price: 125,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Wellaging Diamantdermabrasion",
        duration: 70,
        description:
          "Porenverfeinerndes und zellerneuerndes Treatment für Ausstrahlung und den perfekten GLOW!",
        features: [
          "Hautanalyse und Reinigung",
          "Kräuter Dampfkompressen",
          "Vorbehandlung",
          "Diamantdermabrasion",
          "Nachbehandlung",
          "Intensivmaske mit Versiegelungseffekt",
          "Modulierte Dekollete - Gesichts- Arm und Nackenmassage",
          "Tages- oder Nachtpflege",
          "Auf Wunsch kl. Makeup",
        ],
        price: 135,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Wellaging Spezial",
        duration: 90,
        description:
          "Ganzheitliches, tiefenwirksames Verwöhnprogramm mit Soforteffekt und hochwirksamen natürlichen Aktivstoffen für eine gesunde vitalisierte Ausstrahlung Ihrer Haut.",
        features: [
          "Hautanalyse und Reinigung",
          "Lymphstimulierende Massage",
          "Kräuter Dampfkompressen",
          "Microdermabrasion und Tiefenreinigung- Augenbrauenkorrektur",
          "Spezial Serum",
          "Modulierte Dekollete - Gesichts- und Nackenmassage",
          "Intensivmaske einschl. Hand- und Armmassage",
          "Tages- oder Nachtpflege",
          "Auf Wunsch kl. Makeup",
        ],
        price: 129,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Wellaging Supreme Eye & Face Lift",
        duration: 90,
        description:
          "Intensive Lifting Behandlung mit Gua Sha Massage für eine sichtbare gefestigte Augenpartie, straffe Gesichtskonturen und Glow Effekt.",
        features: [],
        price: 149,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Wellaging Face & Body Kräuterstempel",
        duration: 120,
        description:
          "Ganzheitliche Gesichts- und Körperbehandlung, die Verspannungen löst, die Durchblutung anregt und die Haut zum Strahlen bringt! Erleben Sie, wie Körper und Geist in perfekter Harmonie zur Ruhe kommen.",
        features: [],
        price: 149,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Face,
        title: "Power Lift Männer",
        duration: 60,
        description:
          "Natürliche High Tech Power Pflege speziell auf die Männerhaut abgestimmt, wirkungsstark und kraftvoll.",
        features: [
          "Hautanalyse",
          "Reinigung",
          "Tiefenreinigung mit Spezialpeeling",
          "Spezialserum",
          "Intensivmaske mit Gesichtsmassage",
          "Tagespflege und Beratung",
        ],
        price: 80,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Body,
        title: "Relaxing pur",
        duration: 40,
        description:
          "Gesichts-, Hals-, Dekolleté-/Nackenmassage oder alternativ Gesichts-/Kopfmassage.",
        features: ["Reinigung", "Massage", "Tagespflege und Beratung"],
        price: 65,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Body,
        title: "Deep Relax",
        duration: 45,
        description: "Wohltuende & tiefenentspannende Rückenmassage.",
        features: [],
        price: 65,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Body,
        title: "Feet Relax",
        duration: 45,
        description:
          "Beruhigendes Fußbad mit anschließender durchblutungsfördernder und stressreduzierenden Fußmassage.",
        features: [],
        price: 60,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Wimpern färben",
        duration: 0,
        durationText: "innerhalb der Benadlung",
        features: [],
        price: 16,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Wimpern färben",
        duration: 0,
        durationText: "alleiniger Termin",
        features: [],
        price: 19,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Brauenstyling",
        duration: 0,
        durationText: "alleiniger Termin",
        features: [],
        price: 12,
        priceTextBefore: "ab",
        priceTextAfter: "je nach Aufwand",
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Brauen-Lamination",
        duration: 0,
        features: [],
        price: 65,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Brauen färben",
        duration: 0,
        durationText: "innerhalb der Benadlung",
        features: [],
        price: 11,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Brauen färben",
        duration: 0,
        durationText: "alleiniger Termin",
        features: [],
        price: 14,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Waxing Oberlippe",
        duration: 0,
        features: [],
        price: 16,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Waxing Kinn",
        duration: 0,
        features: [],
        price: 12,
        priceTextBefore: "ab",
        priceTextAfter: "je nach Aufwand",
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Upgrades,
        title: "Gesichtsflaum entfernen",
        duration: 0,
        features: [],
        price: 15,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Makeup,
        title: "How to Makeup Coaching",
        duration: 100,
        description:
          "Wir erarbeiten gemeinsam Ihr persönliches Wunsch-Makeup und Sie werden nach dem Coaching sehr viel sicherer im Umgang mit Ihrem Makeup sein. Zum Nachlesen und Reflektieren erhalten Sie eine ausführliche Informations-Mappe.",
        features: [
          "Pflege und Vorbereitung der Haut",
          "Die richtige Grundierung finden",
          "Optimale Augenbrauengestaltung",
          "Natürliches Augen Make-up- und Betonung",
          "Rouge - der Frischekick und Modellierung",
          "Lippen Make-up und typgerechte Farbwahl",
          "Übung für Zuhause",
        ],
        price: 99,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Makeup,
        title: " How to Makeup Quick Day",
        duration: 30,
        description: "Das natürliche Makeup für Alltag und Business.",
        features: [
          "Typgerechtes, natürliches Makeup mit dezenter Farbgestaltung",
          "Grundierung, Lidschatten, Wimperntusche, Rouge, Lippenstift oder Gloss",
        ],
        price: 45,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Makeup,
        title: "How to Makeup Special Night",
        duration: 60,
        description: "Das glamouröse Makeup für besondere Anlässe.",
        features: [
          "Typgerechtes, glamouröses Makeup für den großen Auftritt oder besonderen Abendanlass",
          "Deckendes und gut haftendes Makeup, zartem Contouring, Smokey Eyes mit Traumwimpern, Lippenstift oder Gloss",
        ],
        price: 65,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Makeup,
        title: "How to Makeup Fototermin",
        duration: 60,
        description: "Das perfekte Makeup für Ihr Schwarz-Weiß oder Farbfoto.",
        features: ["Individuelle Wunsch Abstimmung entsprechend dem Anlass"],
        price: 75,
        extraPrices: [],
      },
      {
        type: TreatmentTypes.Makeup,
        title: "How to Makeup Special Braut",
        duration: 0,
        durationText: "Braut - 2 Termine",
        description: "Das besondere Makeup für Ihren schönsten Tag.",
        features: [
          "Individuelle Wunsch Abstimmung entsprechend dem Anlass",
          "Mit Vorbesprechung und Probe-Makeup",
        ],
        price: 170,
        priceTextBefore: "ab",
        extraPrices: [],
      },
    ];
  }
}
