<header>
  <!--<div class="nav-info-panel">
    <div class="container">
      <p>
        Leider ist mein Institut bis zum 31. Januar geschlossen. Falls Sie Beratung zu Hautpflege oder Produkten benötigen, können Sie mir gerne eine Email zwecks Terminvereinbarung senden: <a
        class="btn btn-link p-0 info-panel-link"
        href="/"
      >
        info@schoenheitlich.de
      </a>. <br> Bleiben Sie gesund und alles Gute für Sie! <br> Ihre Carmen Jelinek
      </p>
    </div>
  </div>-->

  <div class="nav--top">
    <div class="row p-3" *ngIf="!displaySmallerLg">
      <div class="col-12 text-center">
        <a class="navbar-brand" routerLink="/">
          <img
            height="150"
            src="../../../../assets/img/logos/logo.svg"
            alt="SCHÖNheitlich"
          />
        </a>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <div class="navbar-translate">
          <div *ngIf="displaySmallerLg" class="navbar-header ml-auto mr-auto">
            <a class="navbar-brand" routerLink="/">
              <img
                src="../../../../assets/img/logos/logo.svg"
                alt="SCHÖNheitlich"
                width="125"
              />
            </a>
          </div>

          <button
            #toggleButtonTop
            class="navbar-toggler navbar-burger"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="toggleSidebar()"
          >
            <div class="nav-icon">
              <div></div>
            </div>
          </button>
        </div>

        <div class="navbar-collapse">
          <ul class="navbar-nav ml-auto mr-auto text-center">
            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" routerLink="/behandlungen">
                Organic Treatments
              </a>
            </li>

            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" routerLink="/makeup"> Organic Makeup </a>
            </li>

            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" routerLink="/produkte">Organic Produkte</a>
            </li>

            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" [routerLink]="'/workshops-und-vortraege'">
                Workshops & Vorträge
              </a>
            </li>

            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" routerLink="/ueber-mich">Über mich</a>
            </li>

            <li class="nav-item" (click)="closeSidebar()">
              <a class="nav-link" routerLink="/impressionen">Impressionen</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <nav #nav class="navbar navbar-expand-lg fixed-top nav--up">
    <div class="container">
      <div class="navbar-translate">
        <div class="navbar-header">
          <a class="navbar-brand" routerLink="/">
            <img
              src="/assets/img/logos/logo-nav.svg"
              [height]="30"
              alt="SCHÖNheitlich"
            />
          </a>
        </div>

        <button
          #toggleButton
          class="navbar-toggler navbar-burger"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggler"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="toggleSidebar()"
        >
          <div class="nav-icon">
            <div></div>
          </div>
        </button>
      </div>

      <div class="navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" (click)="closeSidebar()">
            <a class="nav-link nav-link--small" routerLink="/behandlungen">
              Organic Treatments
            </a>
          </li>

          <li class="nav-item" (click)="closeSidebar()">
            <a class="nav-link nav-link--small" routerLink="/makeup">
              Organic Makeup
            </a>
          </li>

          <li class="nav-item" (click)="closeSidebar()">
            <a class="nav-link nav-link--small" routerLink="/produkte"
              >Organic Produkte</a
            >
          </li>

          <li class="nav-item" (click)="closeSidebar()">
            <a
              class="nav-link nav-link--small"
              [routerLink]="'/workshops-und-vortraege'"
            >
              Workshops & Vorträge
            </a>
          </li>

          <li class="nav-item" (click)="closeSidebar()">
            <a class="nav-link nav-link--small" routerLink="/ueber-mich"
              >Über mich</a
            >
          </li>

          <li class="nav-item" (click)="closeSidebar()">
            <a class="nav-link nav-link--small" routerLink="/impressionen"
              >Impressionen</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
