import { Component, OnInit } from '@angular/core';
import {
  Treatment,
  TreatmentService,
  TreatmentTypes
} from 'src/app/core/services/treatment.service';

@Component({
  selector: 'app-makeup-cards',
  templateUrl: './makeup-cards.component.html',
  styleUrls: ['./makeup-cards.component.scss']
})
export class MakeupCardsComponent implements OnInit {
  makeupTreatments: Treatment[] = [];

  constructor(private treatmentService: TreatmentService) {}

  ngOnInit(): void {
    this.makeupTreatments = this.treatmentService.getTreatmentsByType(
      TreatmentTypes.Makeup
    );
  }
}
