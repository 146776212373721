import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  quote: string =
    'Ich habe es mir zur Aufgabe gemacht, etwas Besonderes für Sie zu schaffen.';
  quoteImg: string = 'assets/img/wakeup.jpg';
  quoteAuthor: string = 'Carmen Jelinek';

  constructor() {}
}
