import { Component } from '@angular/core';

@Component({
  selector: 'app-home-treatments',
  templateUrl: './home-treatments.component.html',
  styleUrls: ['./home-treatments.component.scss']
})
export class HomeTreatmentsComponent {
  constructor() {}
}
