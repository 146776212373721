import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage } from 'ngx-gallery';
import {
  ResponsiveBreakpointsService,
  ResponsiveBreakpoints
} from 'src/app/core/services/responsive-breakpoints.service';

@Component({
  selector: 'app-impressions-gallery',
  templateUrl: './impressions-gallery.component.html',
  styleUrls: ['./impressions-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpressionsGalleryComponent implements OnInit, AfterViewInit {
  @ViewChild('gallery', { static: false })
  gallery: any;
  @ViewChild('galleryWrapper', { static: false })
  galleryWrapper: any;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  private thumbnailMargin: number = 32;
  private thumbnailColumns: number;
  private galleryWidth: number;

  constructor(
    private cdRef: ChangeDetectorRef,
    private responsiveBreakpoints: ResponsiveBreakpointsService
  ) {}

  ngOnInit(): void {
    this.galleryImages = [
      {
        small: './assets/img/impressions/impression-1.jpg',
        medium: './assets/img/impressions/impression-1.jpg',
        big: './assets/img/impressions/impression-1.jpg'
      },
      {
        small: './assets/img/impressions/impression-2.jpg',
        medium: './assets/img/impressions/impression-2.jpg',
        big: './assets/img/impressions/impression-2.jpg'
      },
      {
        small: './assets/img/impressions/impression-3.jpg',
        medium: './assets/img/impressions/impression-3.jpg',
        big: './assets/img/impressions/impression-3.jpg'
      },
      {
        small: './assets/img/impressions/impression-4.jpg',
        medium: './assets/img/impressions/impression-4.jpg',
        big: './assets/img/impressions/impression-4.jpg'
      },
      {
        small: './assets/img/impressions/impression-5.jpg',
        medium: './assets/img/impressions/impression-5.jpg',
        big: './assets/img/impressions/impression-5.jpg'
      },
      {
        small: './assets/img/impressions/impression-6.jpg',
        medium: './assets/img/impressions/impression-6.jpg',
        big: './assets/img/impressions/impression-6.jpg'
      },
      {
        small: './assets/img/impressions/impression-7.jpg',
        medium: './assets/img/impressions/impression-7.jpg',
        big: './assets/img/impressions/impression-7.jpg'
      },
      {
        small: './assets/img/impressions/impression-8.jpg',
        medium: './assets/img/impressions/impression-8.jpg',
        big: './assets/img/impressions/impression-8.jpg'
      },
      {
        small: './assets/img/impressions/impression-9.jpg',
        medium: './assets/img/impressions/impression-9.jpg',
        big: './assets/img/impressions/impression-9.jpg'
      },
      {
        small: './assets/img/impressions/impression-10.jpg',
        medium: './assets/img/impressions/impression-10.jpg',
        big: './assets/img/impressions/impression-10.jpg'
      },
      {
        small: './assets/img/impressions/impression-11.jpg',
        medium: './assets/img/impressions/impression-11.jpg',
        big: './assets/img/impressions/impression-11.jpg'
      },
      {
        small: './assets/img/impressions/impression-12.jpg',
        medium: './assets/img/impressions/impression-12.jpg',
        big: './assets/img/impressions/impression-12.jpg'
      },
      {
        small: './assets/img/impressions/impression-13.jpg',
        medium: './assets/img/impressions/impression-13.jpg',
        big: './assets/img/impressions/impression-13.jpg'
      },
      {
        small: './assets/img/impressions/impression-14.jpg',
        medium: './assets/img/impressions/impression-14.jpg',
        big: './assets/img/impressions/impression-14.jpg'
      }
    ];

    const rows3Columns: number = Math.round(this.galleryImages.length / 3);
    const rows2Columns: number = Math.round(this.galleryImages.length / 2);
    const row1Columns: number = Math.round(this.galleryImages.length / 1);

    this.galleryOptions = [
      {
        image: false,
        thumbnailsColumns: 3,
        thumbnailsRows: rows3Columns,
        thumbnailMargin: this.thumbnailMargin,
        thumbnailsMargin: this.thumbnailMargin,
        thumbnailsArrows: false,
        thumbnailsPercent: 100,
        previewInfinityMove: true,
        previewSwipe: true,
        previewZoom: true,
        previewRotate: true
      },
      {
        breakpoint: 767,
        thumbnailsColumns: 2,
        thumbnailsRows: rows2Columns
      },
      {
        breakpoint: 575,
        thumbnailsColumns: 1,
        thumbnailsRows: row1Columns
      }
    ];
  }

  ngAfterViewInit(): void {
    this.responsiveBreakpoints.responsiveBreakpoints$
      .pipe()
      .subscribe((rB: ResponsiveBreakpoints) => {
        if (rB.displayXs) {
          this.thumbnailColumns = 1;
        } else if (rB.displaySm) {
          this.thumbnailColumns = 2;
        } else {
          this.thumbnailColumns = 3;
        }

        if (!this.galleryWidth) {
          this.onResize();
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const width: number = this.galleryWrapper.nativeElement.clientWidth;

    this.galleryWidth = width;
    this.changeGallery();
    if (width !== this.galleryWidth) {
    }
  }

  private changeGallery(): void {
    const rows: number = Math.round(
      this.galleryImages.length / this.thumbnailColumns
    );
    const rowHeight: number = this.galleryWidth / this.thumbnailColumns;
    const marginHeight: number = (rows - 1) * this.thumbnailMargin;
    const galleryHeight: number = rows * rowHeight + marginHeight;

    this.gallery.height = `${galleryHeight}px`;

    this.cdRef.detectChanges();
  }
}
