<section>
  <div
    class="section"
    [ngClass]="theme ? theme : ''"
    [class.section--no-padding]="noPadding"
  >
    <div
      [class.section-image]="backgroundImg"
      [style.background-image]="backgroundImg ? backgroundImg : ''"
      [style.background-position]="'center ' + backgroundPos"
    >
      <div [class.container]="!noContainer">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</section>
