<app-section theme="secondary">
  <app-section-image-detail
    imgSrc="./assets/img/makeup-1.jpg"
    [imgRight]="true"
  >
    <h3 class="title">Organic Makeup</h3>

    <div class="divider divider--primary"></div>

    <p>
      I love Makeup! Ein natürlich wirkendes Makeup macht Sie schöner und
      selbstbewusster. Die Dosis und das gekonnte Auftragen macht hier den
      Unterschied. Das Makeup kann Ihre ganz persönlichen Vorzüge des Gesichtes
      verstärken und hervorheben.
    </p>

    <p>
      Ich möchte Sie darin unterstützen und coachen, damit Sie Spaß und Freude
      am Umgang mit Ihren Produkten haben. Ganz nach dem Motto: Weniger ist
      MEHR!
    </p>

    <a class="btn btn-outline-primary mt-4 mt-md-3" routerLink="/makeup">
      Erfahre mehr
    </a>
  </app-section-image-detail>
</app-section>
