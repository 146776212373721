import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import {
  ResponsiveBreakpointsService,
  ResponsiveBreakpoints
} from './core/services/responsive-breakpoints.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArrangeDateComponent } from './shared/components/arrange-date/arrange-date.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showScrollUpBtn: boolean = false;
  hideSocialButtons: boolean = false;
  infoBtnWithIcon: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private responsiveBreakpointsService: ResponsiveBreakpointsService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.responsiveBreakpointsService.responsiveBreakpoints$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rB: ResponsiveBreakpoints) => {
        this.infoBtnWithIcon = rB.displaySmallerLg;
        this.hideSocialButtons = rB.displayXs;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openInfoModal(): void {
    this.modalService.show(ArrangeDateComponent);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollY: number = window.scrollY;

    const isOnBottom: boolean =
      scrollY + window.innerHeight + 500 >= document.body.clientHeight;

    this.showScrollUpBtn = scrollY > 2000 || isOnBottom;
  }

  scrollUp(): void {
    window.scrollTo(0, 0);
  }
}
