import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input()
  text: string;
  @Input()
  backgroundImg: string;
  @Input()
  backgroundPos: string = 'center';

  constructor() {}
}
