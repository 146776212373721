import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ResponsiveBreakpointsService,
  ResponsiveBreakpoints
} from 'src/app/core/services/responsive-breakpoints.service';

@Component({
  selector: 'app-section-image-detail',
  templateUrl: './section-image-detail.component.html',
  styleUrls: ['./section-image-detail.component.scss']
})
export class SectionImageDetailComponent implements OnInit, OnDestroy {
  @Input('imgRight')
  set _imgRight(value: boolean) {
    this.imgRight = value;
    this.imgLeft = !this.imgRight;
  }
  @Input()
  imgSrc: string;

  displayXl: boolean;
  displaySmallerMd: boolean;
  imgLeft: boolean = true;
  imgRight: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private responsiveBreakpointsService: ResponsiveBreakpointsService
  ) {}

  ngOnInit(): void {
    this.responsiveBreakpointsService.responsiveBreakpoints$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rB: ResponsiveBreakpoints) => {
        this.displayXl = rB.displayXl;
        this.displaySmallerMd = rB.displaySmallerMd;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
