import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {
  @Input()
  noContainer: boolean = false;
  @Input()
  noPadding: boolean = false;
  @Input()
  set backgroundImg(value: string) {
    this._backgroundImg = `url('${value}')`;

    this.noPadding = !!this._backgroundImg;
  }
  get backgroundImg(): string {
    return this._backgroundImg;
  }
  @Input()
  backgroundPos: string = 'center';
  @Input()
  set theme(value: string) {
    this._theme = `section--${value}`;
  }
  get theme(): string {
    return this._theme;
  }

  private _backgroundImg: string;
  private _theme: string;

  constructor() {}
}
