import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-quote',
  templateUrl: './section-quote.component.html',
  styleUrls: ['./section-quote.component.scss']
})
export class SectionQuoteComponent {
  @Input()
  backgroundImg: string;
  @Input()
  quote: string;
  @Input()
  author: string;

  constructor() {}
}
