import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SectionComponent } from './components/section/section.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SectionTemplateDetailComponent } from './components/section-template-detail/section-template-detail.component';
import { SectionImageDetailComponent } from './components/section-image-detail/section-image-detail.component';
import { SectionQuoteComponent } from './components/section-quote/section-quote.component';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { MapComponent } from './components/map/map.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageHeaderTextComponent } from './components/page-header-text/page-header-text.component';
import { SectionTemplateComponent } from './components/section-template/section-template.component';
import { TreatmentCardComponent } from './components/treatment-card/treatment-card.component';
import { ArrangeDateComponent } from './components/arrange-date/arrange-date.component';
import { EventCardComponent } from './components/event-card/event-card.component';

@NgModule({
  declarations: [
    SectionComponent,
    SectionHeaderComponent,
    SectionTemplateDetailComponent,
    SectionImageDetailComponent,
    SectionQuoteComponent,
    MapComponent,
    PageHeaderComponent,
    PageHeaderTextComponent,
    SectionTemplateComponent,
    TreatmentCardComponent,
    ArrangeDateComponent,
    EventCardComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    AgmCoreModule,
    AgmSnazzyInfoWindowModule,
    ModalModule
  ],
  exports: [
    SectionComponent,
    SectionHeaderComponent,
    SectionTemplateDetailComponent,
    SectionImageDetailComponent,
    SectionQuoteComponent,
    AgmCoreModule,
    MapComponent,
    PageHeaderComponent,
    PageHeaderTextComponent,
    SectionTemplateComponent,
    TreatmentCardComponent,
    ArrangeDateComponent,
    EventCardComponent
  ],
  entryComponents: [ArrangeDateComponent]
})
export class SharedModule {}
