<app-section theme="secondary">
  <app-section-image-detail
    [imgRight]="true"
    imgSrc="./assets/img/products-1.jpg"
  >
    <h3 class="title">Organic Produkte</h3>

    <div class="divider divider--primary"></div>

    <p>
      Ich verwende nur auserlesene, hochwirksame Naturkosmetik von Manufakturen,
      die mit viel Liebe, Nachhaltigkeit und langjähriger Erfahrung Ihre Pflege
      & Makeup Produkte herstellen.
    </p>

    <p>
      Gesunde Hautpflege, innere Balance und positive Ausstrahlung gehen nur im
      Einklang mit der Natur.
    </p>

    <a class="btn btn-outline-primary mt-4 mt-md-3" routerLink="/produkte">
      Erfahre mehr
    </a>
  </app-section-image-detail>
</app-section>
