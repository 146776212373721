import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ResponsiveBreakpointsService,
  ResponsiveBreakpoints
} from '../services/responsive-breakpoints.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild('nav', { static: false })
  nav: any;
  @ViewChild('toggleButton', { static: false })
  toggleButton: any;
  @ViewChild('toggleButtonTop', { static: false })
  toggleButtonTop: any;

  displaySmallerLg: boolean = true;

  private sidebarVisible: boolean = false;
  private navShowOffset: number = 250;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private responsiveBreakpointsService: ResponsiveBreakpointsService
  ) {}

  ngOnInit(): void {
    this.responsiveBreakpointsService.responsiveBreakpoints$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rB: ResponsiveBreakpoints) => {
        this.displaySmallerLg = rB.displaySmallerLg;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSidebar(): void {
    if (!this.sidebarVisible) {
      this.openSidebar();
    } else {
      this.closeSidebar();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const offset: number = window.pageYOffset;

    if (offset > this.navShowOffset) {
      if (this.nav.nativeElement.classList.contains('nav--up')) {
        this.nav.nativeElement.classList.remove('nav--up');
        this.nav.nativeElement.classList.add('nav--down');
      }
    } else {
      if (this.nav.nativeElement.classList.contains('nav--down')) {
        this.nav.nativeElement.classList.remove('nav--down');
        this.nav.nativeElement.classList.add('nav--up');
      }
    }
  }

  closeSidebar(): void {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('nav-open');

    this.toggleButton.nativeElement.classList.remove('nav-icon--open');
    this.toggleButtonTop.nativeElement.classList.remove('nav-icon--open');

    this.sidebarVisible = false;
  }

  openSidebar(): void {
    console.log('open');
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('nav-open');

    this.toggleButton.nativeElement.classList.add('nav-icon--open');
    this.toggleButtonTop.nativeElement.classList.add('nav-icon--open');

    this.sidebarVisible = true;
  }
}
