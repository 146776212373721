import { Component } from '@angular/core';

@Component({
  selector: 'app-home-makeup',
  templateUrl: './home-makeup.component.html',
  styleUrls: ['./home-makeup.component.scss']
})
export class HomeMakeupComponent {
  constructor() {}
}
